/* 
Color Scheme:
#111716 eerie black
#92374d solid pink
#094074 indigo dye
#3c6997 queen blue
#fdfffc baby powder
*/


html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    background: #094074;
    background: linear-gradient(to right bottom, #094074, #3C6997);
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-x: hidden;

}

body::-webkit-scrollbar {
    display: none;
}

h1.new-line {
    white-space: pre-line;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

a.linkColorFix {
    color: #111716;
}

a.linkColorFix:hover {
    color: #3C6997
}

a.linkColorFixWhite {
    color: #FDFFFC
}

a.linkColorFixWhite:hover {
    color: #92374d
}

h1,
h2 {
    color: #FDFFFC;
}

h1.dark-text,
h2.dark-text {
    color: #111716;
}

h1.cursive_light,
h2.roboto_light {
    color: #FDFFFC
}

h1.sep {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0 !important;
}

.fontAwesomeStyle {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #FDFFFC;
    font-size: 70px;
}

;

nav {
    background-color: #111716 !important;
    color: #111716 !important;

}

.sticky-nav {
    background-color: #111716 !important;
    position: sticky;
    top: 0;
}


nav a {
    color: #FDFFFC !important;
}

nav a.notActive {
    font-weight: normal;
}

nav a.activePick {
    font-weight: bold;
}

nav a.name {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 12px;
}

nav img {
    vertical-align: middle;
}

figure {
    display: inline-block;
    margin: 15px;
    text-align: center;
}

figure img {

    vertical-align: top;
}

figure figcaption {
    color: #FDFFFC;
    text-align: center;
    padding-top: 5px;
}

h1.cursive,
h2.cursive {
    font-family: Snell Roundhand, cursive;
    font-weight: bold;
}

h2.robot {
    font-family: "Roboto";
    font-weight: 400;
}

p.card-text,
h5.card-text,
ul.card-text,
h4.card-title {
    color: #111716 !important;
}

div.card {
    z-index: 1;
    background-color: #FDFFFC !important;
}

a.card-text {
    color: #111716 !important;
}

a.card-text:visited {
    color: #92374d !important;
}

.laughBeam {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #FDFFFC;
    font-size: 80px;
}

h3.timeline-title {
    padding-bottom: 20px;
    font-weight: bolder;
}

h3.timeline-title-close {
    font-weight: bolder;
}

h4.timeline-subtitle {
    font-weight: bold;
}

h5.timeline-subtitle {
    font-weight: bold;
}

h6.timeline-subtitle {
    font-weight: bold;
}

p.timeline-content {
    margin: 0em 0 0 !important;
    padding-bottom: 8px;
    padding-left: 20px;
}

p.timeline-content-last {
    margin: 0em 0 0 !important;
    padding-left: 20px;
}

p.timeline-overview {
    margin: 0em 0 0 !important;
    padding-bottom: 8px;
}

ul.timeline-content {
    font-size: .9rem !important;
}


span.vertical-timeline-element-date {
    font-size: 1.1rem !important;
    color: #FDFFFC !important;
    text-shadow:
        0.06em 0 #111716,
        0 0.06em #111716,
        -0.06em 0 #111716,
        0 -0.06em #111716;
}

a.Nav-text {
    padding-right: 1rem !important;
}

a.navbar-brand {
    height: 100px;
}

a.img {
    position: relative;
}

p.speech {
    display: inline-block !important;
    vertical-align: top !important;
    position: relative;
    top: -.5rem;
    left: -1rem;
    width: 100px;
    height: 40px;
    text-align: center;
    font-size: 12px;
    color: #111716;
    line-height: 30px;
    font-weight: bold;
    background-color: #FDFFFC;
    border: 4px solid #3C6997;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 1px 1px 2px #3C6997;
    -moz-box-shadow: 1px 1px 2px#3C6997;
    box-shadow: 1px 1px 2px #3C6997;
}

p.speech:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 5px;
    top: 32px;
    border: 8px solid;
    border-color: #3C6997 transparent transparent #3C6997;
}

p.speech:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 8px;
    top: 30px;
    border: 4px solid;
    border-color: #FDFFFC transparent transparent #FDFFFC;
}

button.navbar-toggler {
    color: #FDFFFC !important;
}



@media only screen and (max-width: 600px) {

    p.speech {
        position: absolute;
        top: .3rem;
        left: 5rem;
        width: 100px;
        height: 40px;
        padding-bottom: 0rem;
        text-align: center;
        font-size: 12px;
        color: #111716;
        line-height: 31px;
        font-weight: bold;
        background-color: #FDFFFC;
        border: 4px solid #3C6997;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        -webkit-box-shadow: 1px 1px 2px #3C6997;
        -moz-box-shadow: 1px 1px 2px#3C6997;
        box-shadow: 1px 1px 2px #3C6997;
    }

    p.speech:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 5px;
        top: 32px;
        border: 8px solid;
        border-color: #3C6997 transparent transparent #3C6997;
    }

    p.speech:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 8px;
        top: 30px;
        border: 4px solid;
        border-color: #FDFFFC transparent transparent #FDFFFC;
    }

    button.navbar-toggler {
        color: #FDFFFC !important;
    }

    ul.funFacts, li.funFacts {
        list-style-type: none;
        max-width: 100%;
        text-align: left;
    }
    a.listIcons {
        position: relative;
        float: none !important;
        left: 35%;
        display: inline-block !important; 
    }

    h3.mobileSpacing{
        padding-left: 0% !important;
    }

    p.indent {
       text-align: left;
        margin: 0;
        padding-left: 0px !important;
        padding-bottom: 10px;
        display: inline-block;
        vertical-align: top;
        overflow-wrap: break-word;

    }
    .boxFixMobile{
        left: 5% !important;
    }

    h3 {
        font-size: 1.5rem !important;
    }
    
}

ul.funFacts {
    list-style-type: none;
    width: 400px;
}

a.listIcons {
    float: left;
    /* left: 90%; */
    /* margin: 0 45px 0 0; */
}

li.funFacts {
    padding: 10px;
    overflow: auto;
}



p.indent {
    margin: 0;
    padding-left: 25%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;

}
h3.mobileSpacing{
    padding-left: 25% ;
}
