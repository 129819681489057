// Variables
$red: #ad0000;

// Placeholder selectors
%transition {
  transition: all .25s;
}

/*=Typography=*/

html {
  font-size: 16px;
  overflow-x: hidden;
}

body {
  font-family: 'Open sans', sans-serif;
  font-weight: 300;
  overflow-x: hidden;
}

.navbar a,
.sec-about p {
  text-align: center;
}

.navbar a {
  font-size: 16px;
}

.navbar .fa-external-link {
  font-size: 10px;
}

.main-heading {
  font-family: 'Open sans', sans-serif;
  font-weight: 300;
  text-align: center;
  letter-spacing: 9px;
  text-transform: uppercase;
}

.container > h1 {
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.sec-about a {
  text-decoration: underline;
}

.sec-services{
  text-align: center !important;
}

.sec-services h1{
  color: #111716;
  text-align: center !important;
}

.sec-services h2,
.sec-services p {
  color: #111716;
  font-weight: 300;
  text-align: center;
}

.sec-testimonials p {
  font-style: italic;
}

.sec-contact .btn {
  text-transform: uppercase;
}

.soc-media-ul a {
  text-align: center;
  text-decoration: none;
}

/*=Base=*/

section {
  padding: 5em 0;
}

footer {
  padding: 3em 0;
}

form {
  @media screen and (max-width: 768px) and (min-width: 485px) {
    max-width: 70%;
  }
}

ul {
  // padding: 0;
  // margin: 0;
  list-style-type: disc;
}

a {
  @extend %transition;
}

hr {
  margin-bottom: 3em;
  width: 7%;
}

/*=Layout=*/

.sec-folio .row {
  margin-bottom: 3em;
}

.sec-folio img {
  max-width: 100%;
}

/*Header*/

.navbar-default {
  background: #fff;
}

.navbar .fa-external-link {
  vertical-align: top;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
  color: $red;
}

.navbar-default .navbar-nav>li>a:active {
  color: darken($red, 5%);
}

/*Main*/
.smiley{
  color: #fdfffc;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.emailIcon{
  color: #fdfffc;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.splitline{
  white-space: pre-line
}
.card-img-top{
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sec-main {
  position: relative;
  height: 560px;
  background: url('../images/walking.jpeg') no-repeat center center;
  background-size: cover;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
  }
}

.main-heading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 0;
  margin: auto;
  height: 40px;
  color: #fff;
}

/*About*/

.sec-about a {
  color: #111716;
  text-align: center;
  &:hover,
  &:focus {
    color: $red;
  }
}

/*Services*/

.sec-services {
  background: #f9f9f9;
}

.sec-services i {
  display: block;
  margin: auto;
  width: 45px;
}

.sec-services .fa-paint-brush {
  width: 64px;
}

.sec-services .fa-code {
  width: 68px
}

.sec-services h2 {
  margin: 1em 0;
}

/*Testimonials*/

.sec-testimonials {
  background: #f9f9f9;
}

/*Contact*/

.sec-contact .form-control:focus {
  border-color: $red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(173, 0, 0, .6);
}

.sec-contact .btn {
  padding: .5rem 1.75rem;
  background: #000;
  font-size: .95rem;
  color: #fff;
  
  @extend %transition;
  
  &:hover,
  &:focus {
    background: $red;
    border-color: darken($red, 10%);
  }
}

/*Footer*/

footer {
  background: #000;
}

.soc-media-ul {
  display: flex;
  justify-content: center;
}

.soc-media-ul li:not(:first-child) a {
  margin-left: 1em;
}

.soc-media-ul a {
  display: block;
  padding: 0.5em .65rem;
  font-size: 1rem;
  color: #fff;
  border-radius: 50%;
  border: 2px solid;

  &:hover,
  &:focus {
    color: $red;
  }
}
